<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb
            class="breadcumb--item-color__scoped conteudos_tematicos_section_bc-color"
          >
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <v-col class="d-flex justify-center align-center">
          <img
            width="50px"
            src="../../assets/conteudos_ser_atingido_title_diamond.png"
            alt=""
          />
          <h3 class="ml-3" style="color: #E06919">
            Tempos
          </h3>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card
            borderColor="#426E51"
            title="Nas (entre)linhas do tempo”"
            titleColor="#426E51"
            title_alignment="center"
          >
            <template v-slot:conteudo>
              <p>
                <strong> Dia 05 de novembro de 2015, por volta de 16h</strong>:
                Momento em que o tempo parou para aqueles e aquelas que foram
                atingidos e atingidas pelo rompimento da Barragem de Fundão.
                Centenas de famílias
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'mapa_territorios' }"
                  >das comunidades rurais de Mariana-MG</router-link
                >
                perderam o rumo, tiveram os planos e vidas destroçados, tendo
                sido imposta uma situação de incerteza que tomou conta daquele
                dia e se desdobraria nos anos seguintes: “Estamos morrendo em
                vida”<sup>1</sup>; “Eu vivo por que todo mundo vive, mas não
                tenho mais vida”<sup>2</sup>.
              </p>
              <p>
                São profundas as alterações na consciência e na qualidade da
                vivência do tempo no contexto de um desastre-crime dessa
                magnitude, que obrigou pessoas de comunidades rurais a se
                mudarem para um contexto de vida urbano, cujas referências
                temporais são outras, e as inundam de tarefas indispensáveis
                para a reorganização dessas vidas já atravessadas por tamanha
                violência.
              </p>
              <p>
                Diante da complexidade do problema, a urgência para a resolução
                parece se impor apenas no interesse das empresas responsáveis:
                menos de um ano após o desastre, em 2016,
                <a
                  class="pink--text"
                  style="text-decoration: none;"
                  href="https://agenciabrasil.ebc.com.br/geral/noticia/2016-09/governo-de-mg-autoriza-obra-da-samarco-que-alagara-parte-de-distrito-em"
                  target="_blank"
                  >diques foram construídos rapidamente</a
                >, em um acordo entre as mineradoras e o poder público, mas os
                reassentamentos das comunidades ainda estavam apenas no plano
                das ideias. Apenas em 2018, o reassentamento do novo distrito de
                Bento Rodrigues começou a ser construído e estava previsto para
                ser entregue no primeiro
                <a
                  class="pink--text"
                  style="text-decoration: none;"
                  href="https://g1.globo.com/mg/minas-gerais/desastre-ambiental-em-mariana/noticia/entrega-do-novo-distrito-de-bento-rodrigues-e-prevista-para-2020-diz-presidente-da-renova.ghtml"
                  target="_blank"
                  >semestre de 2020</a
                >, entretanto, nenhuma casa foi entregue na data prevista. E até
                2022, nenhuma casa foi entregue!
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="3"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_01.png"
            alt=""
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_02.png"
            alt=""
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_03.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          class="assessoria_card d-flex flex-column justify-start align-start"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_04.png"
            alt=""
          />
        </v-col>

        <v-col
          class="assessoria_card d-flex flex-column justify-start align-start"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            height="70%"
            src="../../assets/conteudos_ser_atingido_tempos_05.png"
            alt=""
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Essa situação de expectativas não atendidas se agrava no
                  arrastar do processo de reparação dos danos. Neste, a relação
                  com o tempo combina algo de pessoal e vinculado aos
                  sentimentos, com o absoluto e implacável tempo objetivo e
                  quantificável, como o passar das semanas, meses, anos...
                </p>
                <p>
                  O tempo, enquanto categoria, pode ser pensado pelo menos sob
                  duas dimensões: uma mais objetiva, convencionada socialmente,
                  dividida em frações: em segundos, minutos, horas, dias, meses
                  e ano, que, de algum modo pode ser universalizada, isto é,
                  pode-se determinar, quantas horas ou dias se passaram enquanto
                  uma pessoa ou um grupo realizava uma atividade e outra pessoa
                  ou grupo, outra atividade. A segunda concepção possui uma
                  ordem mais subjetiva, que é a forma como cada pessoa percebe,
                  sente esse transcorrer. Seria mais um entendimento
                  individualizado desse “passar do tempo”. Assim, um fenômeno
                  que dentro da cronologia convencional durou fração de hora,
                  pode ter produzido a sensação de uma eternidade a quem o
                  experienciou, em razão da forte emoção vivenciada, como no
                  caso do nascimento de um(a) filho(a) ou o próprio
                  desastre-crime do rompimento da barragem.
                </p>
                <p>
                  Em fevereiro de 2019, uma decisão judicial fixou o dia 27 de
                  agosto de 2020 como prazo final para o
                  <a
                    class="pink--text"
                    style="text-decoration: none;"
                    href="https://g1.globo.com/mg/minas-gerais/noticia/2019/03/13/justica-estipula-2020-como-prazo-final-para-o-reassentamento-das-familias-atingidas-pelo-desastre-da-samarco-em-mariana.ghtml"
                    target="_blank"
                    >reassentamento</a
                  >, sob pena de multa por dia de atraso. Mas, as empresas rés
                  recorreram da decisão e conseguiram adiar esse prazo para o
                  dia 27 de fevereiro de 2021. Entretanto, até 2022 nenhuma casa
                  foi entregue!
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                O que se percebe dos atingidos e atingidas, a partir das falas
                acessadas pelo cuidadoso processo de cadastramento<sup>3</sup>,
                é a grande presença dessas duas dimensões temporais: a subjetiva
                e a objetiva. As urgências por reparação e esperas exaustivas
                por retornos se desdobram até hoje e continuam a produzir
                efeitos em diversas esferas de suas vidas. Em relação ao tempo
                cronológico, já se passaram mais de seis anos do rompimento da
                barragem, e a reparação integral — isto é, a reparação que leva
                em consideração toda a complexidade dos danos causados pela
                Samarco S.A. e suas mantenedoras — está muito longe de ser
                alcançada. Com tristeza, as famílias relembram as mortes
                acontecidas nos últimos anos. Cada falecimento é acompanhado
                pelo desgosto de mais uma pessoa que não pôde vivenciar a
                justiça e retomar seus modos de vida.
              </p>
              <p>
                No início de 2020, com as obras do reassentamento já atrasadas
                (e nenhuma casa entregue!), estas foram suspensas. Também foram
                suspensas, em meados de março de 2020, as negociações na Fase de
                Negociação Extrajudicial (FNE)<sup>4</sup>, as aplicações de
                formulário e as vistorias<sup>5</sup>, sob o argumento da
                impossibilidade de atendimentos em virtude da pandemia de
                Covid-19, em prejuízo da crescente ansiedade daqueles que se
                encontram fora de suas casas e longe das comunidades de origem,
                muitas vezes em condições de vida precárias<sup>6</sup>.
              </p>
            </template>
          </Card>
        </v-col>

        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="5"
        >
          <img
            width="100%"
            height="70%"
            src="../../assets/conteudos_ser_atingido_tempos_06.png"
            alt=""
          />
          <p style="font-size: 0.6em;">
            Manifestação dos atingidos no dia em que deveriam ter sido entregues
            os reassentamentos, em Fevereiro de 2021. Foto: Joice Valverde,
            2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          class="assessoria_card d-flex flex-column justify-start align-start"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_07.png"
            alt=""
          />
        </v-col>

        <v-col
          class="assessoria_card d-flex flex-column justify-start align-start"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_08.png"
            alt=""
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  No que toca à sensação das pessoas atingidas em relação ao
                  passar do tempo — percepção individual, mas que passa por
                  questões sociais como o evento do desastre coletivo —, os
                  relatos são de que parece ter ocorrido uma suspensão. Não do
                  tempo cronológico, medido por dias, meses e anos, que continua
                  a transcorrer, mas uma percepção, subjetiva e ao mesmo tempo
                  comum, de vida interrompida por todos esses anos, de que se
                  vive um tempo de espera, que, diferentemente da agradável
                  espera em um repouso à sombra de uma árvore, é uma espera
                  angustiada, incerta e dolorosa.
                </p>
                <p>
                  Em disputa por definições, são comuns nas tomadas de termo
                  depoimentos que relatam vidas estacionadas, “à espera de uma
                  resolução que nunca chega”. Todos/as estão exaustos/as de
                  tanto aguardar pela reparação integral, a fim de retomar a
                  dignidade e reconstruir suas vidas, de forma justa e coerente,
                  mesmo após passado tanto tempo.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Essa espera não permite um descanso. Não há inércia, falta de
                  ação ou de preocupação, nunca foram cessadas as buscas por
                  justiça. Em meio a uma realidade complexa e uma conjuntura
                  indecifrável, os atingidos e atingidas deparam-se com
                  situações, instituições e linguagens que, de forma não
                  voluntária, passaram a ter que lidar, como “negociação
                  extrajudicial”, “direitos coletivos”, “Ministério Público”,
                  “reassentamento”, “Defensoria Pública”. E ainda, “prescrição”,
                  tema que assombra aqueles/as que lutam pela reparação justa e
                  integral.
                </p>
                <p>
                  Ter o direito prescrito significa, basicamente, perder a
                  pretensão ao direito de ação (direito de acionar a Justiça)
                  devido ao passar do tempo. No caso do rompimento da barragem
                  de Fundão, em Mariana, a demora da Fase de Negociação
                  Extrajudicial começou a esbarrar nessa possibilidade de perda
                  da pretensão ao direito na esfera judicial, uma vez que no
                  Termo de Transação e Ajustamento de Conduta (TTAC) firmado em
                  2018 foi pactuada a interrupção desse prazo prescricional.
                  Entretanto, o referido acordo foi omisso com relação a qual
                  seria então o prazo aplicável (ou mesmo se existe um prazo).
                </p>
                <p>
                  Com a proximidade dos três anos após o TTAC, em 02 outubro de
                  2021, quase seis anos após o rompimento, acendeu-se um alerta
                  para o risco de ser aplicado o prazo prescricional de três
                  anos para a busca da indenização individual, provocando o
                  receio de que, a partir desta data, as famílias não mais
                  pudessem negociar ou ingressar em juízo para exigir seus
                  direitos. E ainda hoje existe uma forte insegurança jurídica
                  com relação à
                  <a
                    style="text-decoration: none"
                    class="pink--text"
                    href="https://mg.caritas.org.br/storage/arquivo-de-biblioteca/October2021/WqfZ3THbCHQMpDkY4gC8.pdf"
                    >(im)prescritibilidade</a
                  >
                  da pretensão de indenização individual das pessoas atingidas
                  pelos danos por elas suportados.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_09.png"
            alt=""
          />
        </v-col>

        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_10.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_11.png"
            alt=""
          />
        </v-col>

        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_12.png"
            alt=""
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Sendo assim, em que pese a dimensão subjetiva do tempo e esta
                  sensação de suspensão da vida, permeada de traumas, dores e
                  impedimentos para retomada das condições anteriores, aliada ao
                  desgaste físico e à exaustão psicológica de se tentar
                  encontrar um caminho, o âmbito temporal objetivo acaba por
                  impor a necessidade de ação. E ação específica, sob pena de
                  vivenciar mais perdas.
                </p>
                <p>
                  Ainda que existam os mais diversos e legítimos argumentos para
                  a defesa da imprescritibilidade desses direitos<sup>7</sup> —
                  tais como a natureza dos danos (ambiental e
                  continuado)<sup>8</sup>, sua complexidade, e ainda a pandemia
                  de Covid-19 —, paira sobre os atingidos e atingidas a ameaça
                  de, pelo decurso do tempo objetivo, não mais poderem
                  reivindicar seus direitos judicialmente. Então, da mesma forma
                  que tiveram suas vidas, com todos seus valores, engolidos pelo
                  mar de lama, as vítimas do desastre-crime da Samarco também
                  são engolidas por este cruel Chronos9 da mitologia grega, “o
                  tempo que tudo devora”, sendo forçadas a agir e compreender
                  este emaranhado temporal que vivenciam na pele.
                </p>
                <p>
                  Ainda nesse sentido da luta por seus direitos e dos possíveis
                  prejuízos do decurso temporal, muitos atingidos e atingidas
                  relatam que passaram a dedicar grande parte de seu tempo aos
                  chamados “eventos do rompimento”, que seriam as reuniões com a
                  Comissão, com a Fundação Renova, audiências — embora exista o
                  sentimento de que “nada se resolve” nessas reuniões.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Alguns relatam que comprometem inclusive suas atividades
                econômicas ao dedicarem-se a tais compromissos para poder lutar
                por seus direitos, em prejuízo de um tempo que poderia ser
                dedicado ao estudo ou trabalho da forma como gostariam ou
                necessitam. Essa demora nas resoluções e para se chegar a um
                acordo é ainda tema de pesquisas que investigam esse intervalo
                para discussão e tomada de decisão como elemento estratégico
                usado pelas empresas para esgotar as vítimas, abrindo margem
                para a culpa por tanta demora recair sobre a própria democracia
                deliberativa, ou seja, esse formato de tomada de decisão
                coletiva e participativa<sup>10</sup>.
              </p>
            </template>
          </Card>
        </v-col>

        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="5"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_13.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center">
            Manifestação dos(as) atingidos(as) em prol da aprovação da matriz de
            danos. Foto: Juliana Carvalho.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_14.png"
            alt=""
          />
        </v-col>

        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_15.png"
            alt=""
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Tal abuso do tempo das vítimas só agrava os danos e as perdas
                  sofridas. Há alguns relatos de que a quantidade de reuniões
                  afetou até mesmo a alimentação, pois as pessoas atingidas
                  passaram a não ter mais tempo para comer da maneira adequada,
                  além do fato de não possuírem mais os alimentos naturais e sem
                  agrotóxicos cultivados nas comunidades.
                </p>
                <p>
                  Em fevereiro de 2021, ao fim de mais um prazo fixado pela
                  justiça para a conclusão dos reassentamentos,
                  <a
                    class="pink--text"
                    style="text-decoration: none;"
                    href="https://g1.globo.com/mg/minas-gerais/noticia/2021/05/04/mariana-dois-meses-apos-fim-de-prazo-renova-ainda-nao-entregou-casas-e-nenhuma-multa-foi-paga.ghtml"
                    target="_blank"
                    >nenhuma casa foi entregue</a
                  >. Ou seja, novamente, o atraso das obras e a demora sem
                  qualquer punição judicial são usados
                  <a
                    class="pink--text"
                    style="text-decoration: none;"
                    href="https://www.brasildefato.com.br/2021/11/05/demora-de-reassentamentos-em-mariana-mg-e-vista-como-estrategia-de-separacao-de-comunidades"
                    target="_blank"
                    >estrategicamente</a
                  >
                  pelas empresas.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Outro exemplo dessa gestão do tempo utilizada de forma
                estratégica é a demora sem justificativas para indenizar
                <sup>11</sup>, gerando uma situação de urgência em receber
                naqueles núcleos familiares atingidos, que se veem pressionados
                a aceitar valores muito inferiores aos correspondentes a todos
                os danos que sofreram. Até setembro de 2021, mais de 250
                famílias procuraram a Cáritas por não terem propostas quanto à
                indenização no prazo estabelecido judicialmente, qual seja: 90
                dias após a disponibilização do dossiê com os levantamento das
                perdas e danos para a Fundação Renova. Em média, quando a
                família não adere de imediato à primeira proposta e solicita de
                reanálise, o processo de negociação demora mais de um ano após o
                ingresso na Fase de Negociação Extrajudicial <sup>12</sup>.
              </p>
            </template>
          </Card>
        </v-col>

        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="5"
        >
          <img
            width="50%"
            src="../../assets/conteudos_ser_atingido_tempos_16.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="5"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_17.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center">
            Obras do reassentamento atrasadas. Foto: Flora Passos, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Em 15 de setembro de 2021, foi colocado o primeiro tijolo no
                reassentamento coletivo de Paracatu de Baixo, evento cuja
                <a
                  class="pink--text"
                  style="text-decoration: none;"
                  href="https://issuu.com/jornalasirene/docs/a_sirene_edicao_66_outubro_2021/6"
                  target="_blank"
                  >comemoração não se justifica</a
                >
                para aqueles que aguardam há quase seis anos. E até hoje,
                <a
                  class="pink--text"
                  style="text-decoration: none;"
                  href="https://g1.globo.com/mg/minas-gerais/noticia/2021/08/27/atingidos-de-mariana-seguem-sem-data-para-receber-casas-6-meses-apos-fim-do-prazo-revolta-e-raiva.ghtml"
                  target="_blank"
                  >NENHUMA CASA FOI ENTREGUE</a
                >.
              </p>
              <p>
                Porém, mais que esse tempo cronológico, a sensibilidade dessas
                pessoas também foi significativamente afetada, com a perda da
                qualidade do uso do tempo, capaz de afetar inclusive sua
                percepção temporal. Pessoas que viviam em terrenos repletos de
                áreas verdes, onde plantavam e já conheciam o tempo de colheita
                de cada verdura/legume que cultivavam, deixaram de experimentar
                esse tempo natural ao se mudarem para áreas urbanas e passaram a
                viver a angústia de uma vida acelerada e que, numa oposição,
                também parece estar parada, com os sonhos e projetos
                interrompidos. E essa qualidade do tempo, que a cada dia
                continua a se perder um pouco, certamente dinheiro algum vai
                poder reparar, muito menos retomar o já perdido.
              </p>
              <p>
                É recorrente nos relatos dos atingidos e atingidas a narrativa
                de que o tempo de lazer, aquele tempo livre em que não se dedica
                à lógica da vida produtiva, também foi limitado após o
                rompimento, muito pelo ritmo e configuração da rotina na cidade.
                Nas comunidades rurais, eles(as) podiam dedicar seu tempo a
                atividades ao ar livre, em contato com a natureza, como “nadar
                nas cachoeiras” ou “pescar nos rios” , por exemplo. Após o
                rompimento, percebe-se naqueles(as) que sofreram um deslocamento
                compulsório para a cidade de Mariana a diminuição e até mesmo o
                desaparecimento dessas práticas. E a população, sobretudo a
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_crianca_adolescente' }"
                  >jovem</router-link
                >, se limita a ficar em casa “mexendo no celular”.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Ou seja, é perceptível dentre os relatos das pessoas atingidas
                que aquele tempo qualitativo é diferente atualmente. Antes do
                rompimento da barragem, suas rotinas eram em torno de suas
                famílias, animais, plantações, vizinhos, e seus sustentos se
                davam em sua maioria por meio de seus cultivos e criações. Após
                o desastre vivido, obrigaram-se a mudar suas vidas e a viver em
                um local onde tiveram que se adaptar (forçadamente) à
                convivência com pessoas desconhecidas, em casas que não são
                suas, se conformando com novos hábitos.
              </p>
              <p>
                Durante uma das etapas do processo de cadastramento, qual seja,
                a Tomada de Termo, observam-se narrativas semelhantes com a
                seguinte: “atualmente a rotina que tinha lá a gente não consegue
                seguir aqui”; “o cenário mudou, a casa mudou, as pessoas também
                de certa forma mudaram, e eu mesmo…”; “as coisas mudaram e foi
                para pior”. O que demonstra insegurança, grande tristeza, falta
                de perspectivas de um futuro diferente ou até aproximado do que
                era vivido antes em meio a uma rotina mais pacata, tranquila e
                também enérgica, entre paixões e escolhas de vida.
              </p>
              <p>
                Sendo assim, aqueles(as) que se envolvem nas reuniões, em meio a
                sensações de impotência, impunidade, injustiça e saudade, que
                fazem parte de seus dias, às vezes sentem que o “tempo passa
                muito depressa”, pois não têm mais tempo, nem condições
                materiais para momentos de lazer como antes, e muitos(as) vivem
                em função do rompimento.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          class="assessoria_card d-flex flex-column justify-center align-center"
          cols="12"
          md="5"
        >
          <img
            width="100%"
            src="../../assets/conteudos_ser_atingido_tempos_18.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center">
            Manifestação dos(as) atingidos(as) no dia da audiência de 02 de
            outubro de 2018, em que foi firmado o TTAC que definiu o
            procedimento para indenização. Foto: Flora Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Nas comunidades rurais, as pessoas relatam que faziam “muita
                coisa em pouco tempo, parecia que o dia rendia mais”, e que
                “hoje em dia o tempo não rende mais”. Muitos(as) têm essa
                percepção de que “o tempo passa rápido na cidade”. “Tem dia que
                a semana passa muito depressa”.
              </p>
              <p>
                Mas também entre aqueles(as) que não se envolvem tanto nas
                reuniões, há uma sensação de não aproveitamento do tempo como
                antes pela dinâmica da nova vida, pela modificação forçada dos
                hábitos, sem muitas alternativas de entretenimento, o que gera
                desânimo, ócio e ansiedade. Muitos(as) relatam que “fica
                esperando o dia passar”, “que antes do rompimento seu tempo era
                melhor utilizado”, “atualmente se sente ocioso/a”, “busca
                alternativas de entretenimento” e “que a falta de atividades
                aumenta sua ansiedade”.
              </p>
              <p>
                De fato, mesmo passados tantos anos após o desastre-crime, é
                como se o tempo — ou a percepção que se tem dele — estivesse
                estagnado, não progredisse, pois suas dores aumentam, por não
                haver justiça e por sentirem suas vidas alteradas e
                transformadas no meio do caos instaurado. No entanto, por ser
                implacável, o tempo objetivo não espera tanta dor ser
                processada. A ausência de ânimo, perda de interesses,
                desesperança, luto, depressão, ansiedade, trazem a sensação de
                vazio e perda do tempo diário, seja por terem muitas atividades
                que foram forçados(as) a desenvolver, seja por terem sido
                obrigados(as) a deixar de fazer coisas que faziam antes.
              </p>
              <p>
                Ou seja, o marco temporal do dia 05 de novembro de 2015 gerou um
                descompasso entre essas duas dimensões do tempo: o cronológico e
                o sensitivo. Pois, antes do rompimento, a regularidade e
                disciplina com que se realizavam as atividades não eram
                determinadas pelo relógio, embora houvesse uma concordância
                entre esse tempo cronológico e essa dimensão sensível. As
                refeições, por exemplo, eram feitas intuitiva e espontaneamente
                mais ou menos no mesmo horário nas comunidades rurais. Segundo
                alguns relatos, “não havia uma obrigação em relação à
                administração do tempo”, “as coisas eram feitas espontaneamente
                dentro de um hábito mais livre”.
              </p>
              <p>
                Após o rompimento, é constante a sensação de que o tempo está
                parado ou de que ele está passando em um ritmo mais lento ou até
                mais acelerado que antes. Ou seja, junto com o rompimento da
                barragem, rompeu-se a continuidade, o ritmo, a fluidez da vida
                dessas pessoas.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <Card
            borderColor="#426E51"
            title="E daqui pra frente?"
            titleColor="#426E51"
            title_alignment="center"
          >
            <template v-slot:conteudo>
              <p>
                São muitas as reflexões acerca do tempo na perspectiva de quem
                sofreu e vive todos os dias esse desastre-crime. As dores
                causadas pelo Mar de Lama nunca serão amenizadas ou indenizadas
                devidamente, pois nada devolverá aquilo que foi perdido.
                Registros foram soterrados, fotos de famílias foram perdidas e
                tornaram-se somente lembranças. Todo o registro de um tempo
                anterior ao rompimento foi apagado violenta e repentinamente.
              </p>
              <p>
                Fundamental também, para essas reflexões, é localizar a
                experiência das pessoas atingidas nesse tempo histórico em que
                estamos inseridas(os), em que vidas são tratadas como
                mercadorias. É de suma importância trazer à tona a dimensão do
                ser atingida(o) por barragem de rejeitos de mineradoras
                multinacionais, que são detentoras de grande poderio econômico,
                numa conjuntura em que certas vidas, certos corpos, são
                dispensáveis e que, em grande medida, são os mesmos corpos das
                pessoas atingidas de Mariana. Assim, fazer frente às mineradoras
                na disputa por direitos não é tarefa simples nesses tempos.
                Atuar como assessoria técnica, escolher o lado das pessoas
                atingidas e trabalhar nas fissuras desse sistema, tampouco.
              </p>
              <p>
                Ficam as indagações, com olhares vazios, perda de esperança,
                sensações de dor e injustiça. Qual é o preço? Há como
                quantificar e valorar todo esse período para que se repare o
                tempo que se sente perdido? Será que o tempo conseguirá
                preencher esses vazios, recompor o meio ambiente e, assim,
                devolver a plenitude de vida de quem foi devastado por um Mar de
                Rejeitos<sup>13</sup>?
              </p>
              <p>
                Nos relatos dos atingidos e atingidas, é a esperança por esses
                dias melhores que sustenta muitas famílias, que não apenas
                esperam, mas “esperançam”, de acordo com a lição freiriana:
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <img src="../../assets/conteudos_ser_atingido_tempos_19.png" alt="" />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <div
            class="inside__card"
            style="border: 1px solid #CCCCCC; border-radius: 4px;"
          >
            <p style="text-align: center; margin-bottom: 0;">
              <strong
                >Autoras: Carolina Lemos <sup>15</sup>, Daniele Calestini
                Dicenzo <sup>16</sup>, Marisa Versiani Elias<sup>17</sup> e
                Teresa Chaves Silva<sup>18</sup>.</strong
              >
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center mt-5 mt-md-0 flex-column"
        >
          <img src="../../assets/conteudos_ser_atingido_tempos_20.png" alt="" />
          <p style="text-align: center; font-size: 0.5em;">
            Estado do reassentamento de Bento Rodrigues no prazo final para
            entrega, em fevereiro de 2021. Foto: Joice Valverde, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup>
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://politica.estadao.com.br/blogs/fausto-macedo/estamos-morrendo-em-vida-diz-vitima-da-grande-tragedia-ambiental-do-fundao/"
                class="pink--text"
              >
                https://politica.estadao.com.br/blogs/fausto-macedo/estamos-morrendo-em-vida-diz-vitima-da-grande-tragedia-ambiental-do-fundao/</a
              >.
            </p>
            <p>
              <sup>2</sup>
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2019/06/MONOGRAFIA-Maryellen5.pdf"
                class="pink--text"
              >
                https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2019/06/MONOGRAFIA-Maryellen5.pdf</a
              >.
            </p>
            <p>
              <sup>3</sup> Cerca de 1.500 núcleos familiares e entidades
              participaram das etapas do cadastro, totalizando mais de 5.000
              pessoas atingidas cadastradas em Mariana-MG (A Sirene, edição
              Fevereiro/2022). A análise das falas para a elaboração desse texto
              foi feita com base em uma amostra de 37 tomadas de termo,
              retiradas aleatoriamente dos dossiês cadastrados.
            </p>

            <p>
              <sup>4</sup> A Fase de Negociação Extrajudicial (FNE) foi criada
              no Termo de Transação e Ajustamento de Conduta (TTAC), no âmbito
              da Ação Civil Pública, como meio prioritário de indenizar os
              atingidos e atingidas. As negociações foram suspensas em 16 de
              março de 2020, devido à necessidade de isolamento social imposta
              pela pandemia de Covid-19, sendo retomadas em formato virtual na
              segunda quinzena de junho de 2020.
            </p>
            <p>
              <sup>5</sup> Conforme acordo judicial, o formulário e a vistoria
              são etapas do Cadastro compulsoriamente realizadas com a presença
              da ATI e de representante das empresas. A despeito da insistência
              da Cáritas em retomada, ainda que adaptada para o momento de
              calamidade da saúde pública, as reaberturas de formulário foram
              retomadas apenas em fevereiro de 2021 e as novas aplicações em
              agosto de 2021. Procedimento substitutivo às vistorias foi também
              proposto pela Cáritas e acatado pelas partes apenas em maio de
              2022.
            </p>
            <p>
              <sup>6</sup> Ainda, em relação às esperas, com a previsão de
              término dos projetos do cadastro e de assessoramento jurídico das
              pessoas atingidas pela ATI, em novembro de 2019 foram iniciadas as
              tratativas para renovação dos projeto. O crime continuado e o
              crescente pleito pelo reconhecimento da condição de atingido fez
              as estimadas 400 famílias em 2018 virem a se configurar em um
              número 4 vezes maior. Diante da adesão dos núcleos familiares e da
              gradativa conquista de confiança por parte das pessoas atingidas,
              viu-se a necessidade de ampliação do quadro profissional e de
              renovação, dada também a complexidade de todo o processo de
              reparação em Mariana e os desafios impostos pela prática, na lida
              com a Fundação Renova. Na contramão da necessidade que estava
              imposta, as negociações para renovação do projeto e ampliação da
              equipe duraram mais de um ano, tendo as atividades do Cadastro e
              da Assessoria Jurídica continuado nesse limbo temporal de modo
              absolutamente precário. Afetando, logicamente, o alcance e a
              qualidade do atendimento prestado aos atingidos e atingidas. Após
              a renovação, que só veio a acontecer em julho de 2021, é que a
              equipe de assessoria jurídica foi remodelada com um quantitativo
              adequado às demandas postas (desde 2019) e os projetos estendidos
              por mais um período. Então, a assessoria jurídica passou a contar
              com 39 profissionais do direito para atuarem no território no
              assessoramento das pessoas atingidas, até 04 de setembro de 2022.
            </p>
            <p>
              <sup>7</sup> O documento “Teses Acerca do Instituto da Prescrição
              de Reparação de Danos Decorrentes do Rompimento da Barragem de
              Fundão: Uma Análise Necessária à Luz dos Direitos Humanos”, foi
              elaborado pela equipe jurídica da Assessoria Técnica da Cáritas
              Brasileira Regional Minas Gerais, e foi e protocolado junto ao
              Ministério Público de Minas Gerais em 02 de julho de 2021, a fim
              de que tal tema, de extrema importância, seja objeto de disputa e
              debate perante as Instituições de Justiça, de modo a ser
              reconhecida e aplicada a imprescritibilidade da reparação civil
              decorrente de dano ambiental ao caso de Mariana, como mais um
              instrumento na luta pela reparação integral dos atingidos e
              atingidas. O documento completo está disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://mg.caritas.org.br/storage/arquivo-de-biblioteca/October2021/WqfZ3THbCHQMpDkY4gC8.pdf"
                class="pink--text"
                >https://mg.caritas.org.br/storage/arquivo-de-biblioteca/October2021/WqfZ3THbCHQMpDkY4gC8.pdf</a
              >.
            </p>
            <p>
              <sup>8</sup> Sobre a prescrição no âmbito penal, remete-se ao
              artigo
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://sites.ufop.br/sites/default/files/gepsa/files/relatorio_final_arquivo_2.pdf?m=1602132619"
                class="pink--text"
                >https://sites.ufop.br/sites/default/files/gepsa/files/relatorio_final_arquivo_2.pdf?m=1602132619</a
              >.
            </p>
            <p>
              <sup>9</sup> Na mitologia grega, o deus Chronos, que engolia seus
              filhos assim que saíam do ventre da mãe, representa esse tempo
              quantitativo, marcado pelo relógio e calendário, enquanto Kairós,
              filho mais jovem de Zeus, que andava rápido, nu e tinha somente um
              cacho de cabelos na testa, representa a percepção qualitativa do
              tempo.
            </p>
            <p>
              <sup>10</sup> Artigo “Deliberando ou protelando por justiça?
              Dinâmicas de remediação corporativa e resistência às vítimas pelas
              lentes do parentalismo: o caso da Fundação Renova no Brasil”, de
              Rajiv Maher, resultado da pesquisa desenvolvida junto às vítimas e
              aos atores envolvidos no rompimento da barragem. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://www.scielo.br/j/cebape/a/qgDchpNgGHXHZbNVCnXyqVM/?lang=pt#"
                class="pink--text"
                >https://www.scielo.br/j/cebape/a/qgDchpNgGHXHZbNVCnXyqVM/?lang=pt#</a
              >.
            </p>
            <p>
              <sup>11</sup> Nota-se, por exemplo, no âmbito da FNE, o reiterado
              descumprimento do prazo de três meses para apresentação da
              proposta indenizatória pela Fundação Renova. Tal situação foi
              constatada no processo de, pelo menos, 259 famílias, de acordo com
              recente levantamento de dados realizado pela Cáritas. Essa
              violação já foi denunciada ao Ministério Público de Minas Gerais,
              por meio de ofícios, para que pleiteasse a aplicação das multas
              correspondentes. Fonte: JABACE, Laís., C MARA, Gabriela. et al.
              Cadastramento das Pessoas Atingidas em Mariana: "para uma
              reparação justa, um cadastro nosso!". In: ALEIXO, Letícia (org.).
              “O direito das comunidades atingidas pela mineração à Assessoria
              Técnica Independente”, 2022.
            </p>
            <p>
              <sup>12</sup> Quando as famílias solicitam reanálise da primeira
              proposta apresentada pela Fundação Renova na Fase de Negociação
              Extrajudicial, considerando exclusivamente os casos acompanhados
              pela assessoria jurídica da Cáritas e finalizados até fevereiro de
              2022, o tempo médio do processo de negociação das indenizações
              individuais é de 380 dias.
            </p>
            <p>
              <sup>13</sup> Sobre o tema da contaminação causada pela lama de
              rejeitos, ver as reportagens:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://www.agazeta.com.br/es/cotidiano/cinco-anos-apos-lama-rio-e-mar-ainda-sofrem-com-contaminacao-diz-estudo-1120"
                class="pink--text"
                >https://www.agazeta.com.br/es/cotidiano/cinco-anos-apos-lama-rio-e-mar-ainda-sofrem-com-contaminacao-diz-estudo-1120</a
              >
              e
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://noticias.r7.com/minas-gerais/contaminacao-em-mariana-mg-pode-provocar-infertilidade-e-aborto-08122019"
                class="pink--text"
                >https://noticias.r7.com/minas-gerais/contaminacao-em-mariana-mg-pode-provocar-infertilidade-e-aborto-08122019</a
              >.
            </p>
            <p>
              <sup>14</sup> FREIRE, Paulo.
              <strong>Pedagogia da Esperança</strong> um reencontro com a
              Pedagogia do Oprimido. Rio de Janeiro: Paz e Terra, 1992.
            </p>
            <p>
              <sup>15</sup> Advogada, bacharela em Direito pela PUC-MG,
              pós-graduada em Direito Administrativo e Advocacia Cível pela
              Escola Superior da Advocacia - ESA/Fumec. Assessora Jurídica na
              ATI Mariana.
            </p>
            <p>
              <sup>16</sup> Advogada, bacharela em Direito pela FUPAC - Mariana,
              pós-graduada em Direito e Processo Penal pela UniDomBosco.
              Assessora Jurídica na ATI Mariana.
            </p>
            <p>
              <sup>17</sup> Advogada popular, bacharela em Direito pela FDCL,
              pós-graduanda em Direitos Humanos, Responsabilidade Social e
              Cidadania Global pela PUC/RS. Assessora Jurídica na ATI Mariana.
            </p>
            <p>
              <sup>18</sup> Advogada, bacharela em Direito pela UFV,
              pós-graduada em Direito e Negócios Imobiliários pela Damásio
              Educacional. Assessora Jurídica na ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_ser_atingido_idosos' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Idosos"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb.vue";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Ser Atingido /",
          href: "conteudos_tematicos_ser_atingido",
        },

        {
          text: "Tempos",
          href: "conteudos_tematicos_ser_atingido_tempos",
        },
      ],
    };
  },
};
</script>

<style scoped>
.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-4.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 0 2em 2em 2em;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
